<!--店铺装修-->
<template>
  <div class="shop-furnish" v-loading="loading">

    <div class="shop-card" v-for="item in shopList" :key="item.id" @dblclick="editorShop(item, '40')">

      <div class="shop-head">
        <span>{{ item.name }}</span>
        <span class="cover-tags">
          <span class="cover-tag" :style="{ 'border-right-color': Number(item.state) === 10 ? '#67C23A' : '#909399' }" />
          <span class="cover-tag-text">{{ Number(item.state) === 10 ? '营业中' : '休息中' }}</span>
        </span>
      </div>

      <div class="global-flex">
        <span class="global-flex-label">营业时间: </span>
        <span class="global-flex-value">{{ item.businessHours }}</span>
      </div>

      <div class="global-flex">
        <span class="global-flex-label">门店店长: </span>
        <span class="global-flex-value">{{ item.contactPersonName }}</span>
      </div>

      <div class="global-flex">
        <span class="global-flex-label">联系电话: </span>
        <span class="global-flex-value">{{ item.contactPhone }}</span>
      </div>

      <div class="global-flex">
        <span class="global-flex-label">门店地址: </span>
        <span class="global-flex-value">{{ item.address }}</span>
      </div>

      <div class="global-flex" @click="openMap(item)">
        <span class="global-flex-label">详细地址: </span>
        <span class="global-flex-value global-flex-remak" :title="item.addressDetail">
          {{ item.addressDetail }}
          <GIcon icon="icon-qidong" />
        </span>
      </div>

      <div class="card-footer">
        <el-button type="warning" size="small" @click="editorShop(item, '40')">编辑</el-button>
      </div>

    </div>

  </div>
</template>

<script>
  import { shopList } from '@/api/public'
  import { Tag } from 'ant-design-vue'
  export default {
    name: 'furnish',
    components: { Tag },
    created() {
      this.init()
    },
    data() {
      return {
        loading: false,
        shopList: []
      }
    },
    methods: {
      init() {
        this.loading = true
        shopList().then(res => {
          this.shopList = res.data
          this.loading = false
        }).catch(() => this.loading = false)
      },
      editorShop(item) {
        this.$emit('change', {
          name: 'furnishDetail',
          appInfo: item,
          editorId: item.id
        })
      },
      openMap(item) {
        const url = `https://uri.amap.com/marker?position=${item.addressLng},${item.addressLat}`
        window.open(url, '_blank')
      }
    }
  };
</script>

<style lang="scss" scoped>
  .shop-furnish{
    width: 100%;
    height: 100%;
    padding: 12px;
    display: flex;
    align-items: baseline;
    overflow-y: auto;
    .shop-card{
      width: 320px;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      padding: 12px;
      box-shadow: $cardShadow;
      margin: 0 12px 12px 0;
      font-size: 14px;
      color: $title1;
      cursor: pointer;
      transition: all .3s;
      &:hover{
        transform: scale(1.05);
      }
      .shop-head{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        border-bottom: 1px $themeBg solid;
        padding-bottom: 12px;
        position: relative;
        .shop-head-icon{
          color: #409EFF;
          font-size: 20px;
          font-weight: bold;
          margin-right: 6px;
        }
        .cover-tags{
          position: absolute;
          right: 0;
          top: 0;
          .cover-tag{
            position: absolute;
            left: -32px;
            top: -12px;
            width: 0;
            height: 0;
            border-right: 64px solid #67C23A;
            border-bottom: 64px solid transparent;
            letter-spacing: 2px;
          }
          .cover-tag-text{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            font-size: 10px;
            display: block;
            width: max-content;
            font-weight: bold;
            color: #fff;
            transform: rotate(46deg) translate(-17px, 11px) scale(.8);
          }
        }
      }
      .card-footer{
        margin-top: 12px;
        border-top: 1px $themeBg solid;
        padding-top: 12px;
        text-align: right;
        position: relative;
      }
      .global-flex{
        align-items:  baseline;
        line-height: 40px;
        font-size: 14px;
        color: $title1;
        .global-flex-label{
          margin-right: 10px;
          min-width: 50px;
          text-align: right;
          font-weight: 500;
        }
        .global-flex-value{
          width: calc(100% - 80px);
          .icon{
            margin-left: 6px;
          }
        }
        .global-flex-remak{
          height: 72px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-height: 24px;
          &:hover{
            color: #409EFF;
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
